// variables
$mobile: 1399.98px;
$desktop: 1919.98px;

.footerMain {
  position: relative;
  z-index: 10;
  .soundToggler {
    @media (max-width: $mobile) {
      display: none;
    }
  }
  display: flex;
  width: 120rem;
  margin: 0 auto;
  padding: 1.75rem 0;
  @media (max-width: $desktop) {
    width: 90rem;
  }
  @media (max-width: $mobile) {
    margin-top: 1.875rem;
    margin-bottom: 1.5625rem;
    padding: 0;
    width: 100%;
  }
  &__container {
    padding: 0 8.125rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $desktop) {
      padding: 0 8.125rem;
    }
    @media (max-width: $mobile) {
      // padding: 0 1.25rem;
      justify-content: center;
      padding: 0;
    }
  }
  &__activities {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 60px;
    background: rgba(41, 47, 79, 0.5);
    padding: 0.625rem 0.8125rem;
    display: flex;
    gap: 0.9375rem;
    @media (max-width: $mobile) {
      gap: 0.5625rem;
    }
  }
  &__activitiesBtn {
    position: relative;
    button {
      display: flex;
      align-items: center;
      border: 0.125rem solid #fff;
      border-radius: 3.75rem;
      height: 3.125rem;
      padding: 0 1.25rem;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 0%;
      color: #fff;
      @media (max-width: $mobile) {
        padding: 0 0.875rem;
        font-size: 1rem;
      }
    }
    img {
      margin-left: 0.625rem;
      @media (max-width: $mobile) {
        scale: 0.8;
        margin-left: 0.3125rem;
      }
    }
  }
  &__activitiesBtn:first-child {
    button {
      background: linear-gradient(87deg, #691ee2 0%, #c09aff 100%);
      transition: opacity 0.25s;
      position: relative;
    }
    button::before {
      content: "";
      background: linear-gradient(-87deg, #691ee2 0%, #c09aff 100%);
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 3.75rem;
      transition: opacity 0.25s ease-in-out;
      right: 0;
    }
    button:hover:before {
      opacity: 1;
    }
    span {
      z-index: 10;
      position: relative;
    }
    img {
      position: relative;
      z-index: 10;
    }
  }
  &__activitiesHint {
    z-index: 10;
    position: absolute;
    border: 2px solid rgba(255, 255, 255, 0.6);
    border-radius: 3.75rem;
    height: 1.75rem;
    display: flex;
    align-items: center;
    background: #7631e7;
    transform: rotate(8deg);
    font-weight: 400;
    font-size: 0.625rem;
    color: #fff;
    padding: 0 0.5625rem;
    top: -0.9375rem;
    right: -1.5625rem;
  }
  &__socials {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    @media (max-width: $mobile) {
      display: none;
    }
  }
  &__twBtn {
    border: 0.125rem solid #fff;
    border-radius: 3.75rem;
    width: 3.125rem;
    height: 3.125rem;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    color: #fff;
    overflow: hidden;
    a {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__twBtn:hover {
    transition: 0.25s;
    color: #000000;
    background-color: #ffffff;
    cursor: pointer;
  }
  &__tgBtn {
    border: 0.125rem solid #fff;
    border-radius: 3.75rem;
    width: 3.125rem;
    height: 3.125rem;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    color: #fff;
    overflow: hidden;
    a {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__tgBtn:hover {
    transition: 0.25s;
    color: #000000;
    background-color: #ffffff;
    cursor: pointer;
  }
}

body::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.8s ease 0s;
  pointer-events: none;
  z-index: 149;
}
.popupTasks-show body::after {
  opacity: 1;
}
.popupTasks {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0 0.625rem;
  transition: visibility 0.8s ease 0s;
  // visibility: hidden;
  pointer-events: none;
  @media (max-width: 768px) {
    padding: 0;
  }
}
.popupTasks_show {
  z-index: 300;
  display: flex;
  overflow: auto;
  pointer-events: auto;
  align-items: center;
}
.popupTasks_show .popupTasks__content {
  display: flex;
}
.popupTasks__wrapper {
  flex-direction: column;
  width: 100%;
  display: flex;
  @media (max-width: $desktop) {
  }
  @media (max-width: $mobile) {
  }
  @media (max-width: 768px) {
  }
}
.popupTasks__content {
  display: none;
  transition: transform 0.3s ease 0s;
  padding: 3.125rem 0;
  border-radius: 1.25rem;
  background: rgb(41, 47, 79);
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 2.1875rem 0;
    width: 21.5625rem;
  }
}

.popupTasks {
  &__close {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;    
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3.125rem;
    @media (max-width: 768px) {
      padding: 0 1.5625rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5625rem;
    }
  }
  &__title {
    h4 {
      font-weight: 900;
      font-size: 2.125rem;
      line-height: 100%;
      color: #fff;
      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
    }
  }
  &__coins {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
    display: flex;
    align-items: center;
    }
  }
  &__coinImg {
    width: 2.1875rem;
    height: 2.1875rem;
    margin-right: 0.625rem;
    @media (max-width: 768px) {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.3125rem;
    }
    img {
      width: 100%;
    }
  }
  &__coinAmount {
    display: flex;
    align-items: center;    
    span {
      padding-top: 5px ;
      font-weight: 900;
      font-size: 2.125rem;
      line-height: 100%;
      color: #fff;
      @media (max-width: 768px) {
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
  }
  &__tabs-btns {
    margin-top: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width: 768px) {
      margin-top: 1.5625rem;
      gap: 0.9375rem;
      padding: 0 1.5625rem;
      // justify-content: space-between;
    }
  }
  &__tabs-btn {
    border-radius: 10px 10px 0 0;
    position: relative;
    z-index: 10;
    button {
      padding: 12px 19px 10px 19px;
      font-weight: 600;
      font-size: 1.375rem;
      color: rgba(255, 255, 255);
      opacity: 0.6;
      text-align: start;
      span {
        @media (max-width: 768px) {
          display: none;
        }
      }
      @media (max-width: 768px) {
        font-size: 0.875rem;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        padding: 0.625rem;
      }
    }
    &.active {
      border-bottom: 1px solid rgba(41, 47, 79);
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      overflow: hidden;
      button {
        opacity: 1;
      }
      @media (max-width: 768px) {
        border: 1px solid #fff;
        border-radius: 10px;
        background: linear-gradient(87deg, #c09aff 0%, #691ee2 90.83%);
      }
    }
    .footerMain__activitiesHint {
      display: flex;
      @media (max-width: 768px) {
        display: flex;
        font-size: 0.625rem;
        padding: 0 3px;
      }
    }
  }
  &__tabs-line {
    height: 1px;
    background-color: #ffffff;
    width: 100%;
    position: absolute;
    bottom: 0;
    @media (max-width: 768px) {
      display: none;
    }
  }
  &__tabs-content {
    margin: 4.0625rem 3.125rem 0 3.125rem;
    @media (max-width: 950px) {
      margin-left: 0;
      margin-right: 0;
    }
    @media (max-width: 768px) {
      margin-top: 2.1875rem;
    }
  }
  &__tabSocial {
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
    @media (max-width: 768px) {
      padding: 0 1.5625rem;
    }
  }
  &__tabSocial-item {
    display: flex;
    align-items: center;
    gap: 1.5625rem;
    justify-content: space-between;
    padding: 0 10.625rem;
    @media (max-width: 768px) {
      padding: 0;
      gap: 0.625rem;
      justify-content: flex-start;
      position: relative;
    }
  }
  &__tabSocial-btn {
    @media (max-width: 768px) {
      width: 100%;
    }
    button {
      border: 2px solid #fff;
      border-radius: 3.75rem;
      padding: 20px 18px;
      width: 400px;
      height: 60px;
      background: linear-gradient(-87deg, #691ee2 33.5%, #c09aff 100%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
      color: #fff;
      transition: opacity 0.25s;
      position: relative;
      @media (max-width: 768px) {
        width: 100%;
        font-size: 0.875rem;
        justify-content: space-between;
      }
    }
    button:disabled {
      background: #292f4f;
    }
    span {  
      z-index: 10;
      position: relative;
      text-align: start;
      max-width: 75%;
    }
  }
  &__tabSocial-reward {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 120px;
    @media (max-width: 768px) {
      width: 100px;
      position: absolute;
      right: 20px;
    }
    span {
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 100%;
      color: #fff;
      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }
    img {
      width: 1.375rem;
      height: 1.375rem;
      margin-left: 5px;
    }
  }
}
